.sharing-select-window-container {
    cursor: crosshair;
    width: 100vw;
    height: 100vh;
    /* background: transparent; */
    /* background-color: rgba(0, 0, 0, 0.4); */
}

.selected-rectangle {
    display: none;
    width: 0px;
    height: 0px;
    position: absolute;
    border: 1px solid rgb(255, 82, 47);

}

.selected-rectangle-button-group {
    border-radius: 0 !important;
    position: relative;
    top: -32px;
    left: 0px;
    display: inline;
    background-color: #333333;

}


.mui_button_start {
    position: relative;
    top: 0;
    margin: 0;
}

.top-left-resize-handler {
    width: 10px;
    height: 10px;
    top: -5px;
    left: -5px;
    border: 2px solid rgb(255, 82, 47);
    position: absolute;

    margin: 0px;
    background-color: rgb(255, 82, 47);
    cursor: nw-resize;
}

.bottom-left-resize-handler {
    width: 10px;
    height: 10px;
    top: calc(100% - 5px);
    left: -5px;
    border: 2px solid rgb(255, 82, 47);
    position: absolute;
    cursor: ne-resize;
    margin: 0px;
    background-color: rgb(255, 82, 47);
}

.top-right-resize-handler {
    width: 10px;
    height: 10px;
    top: -5px;
    left: calc(100% - 5px);
    border: 2px solid rgb(255, 82, 47);
    position: absolute;
    margin: 0px;
    background-color: rgb(255, 82, 47);
    cursor: ne-resize;
}

.bottom-right-resize-handler {
    position: absolute;
    width: 10px;
    height: 10px;
    top: calc(100% - 5px);
    left: calc(100% - 5px);
    border: 2px solid rgb(255, 82, 47);
    margin: 0px;
    background-color: rgb(255, 82, 47);
    cursor: nw-resize;
}

.start-share-screen-button {
    position: absolute;
    color: white !important;
    border: 1px solid rgb(255, 82, 47);
    border-radius: 5px;
    width: 100px;
    height: 40px;
    line-height: 1.6;
    left: calc(50% - 50px);
    top: calc(50% - 20px);
    text-align: center;
    font-weight: bold;
    font-size: 1.3em;
    cursor: pointer;
    vertical-align: middle;
    background-color: rgb(255, 82, 47);
}

.panel-icon-button {
    width: 15px !important;
    height: 15px !important;
}